import styled from 'styled-components';
import helpers from '../../styles/helpers';
import ResponsiveMediaQuery from '../../styles/ResponsiveMediaQuery';
import Flex from '../../styles/Flex';

export default {
  FooterContainer: styled.div`
    height: 165px;
    box-sizing: content-box;
    margin-bottom: 0;
    background: ${(props) => props.theme.colors.secondary};
    align-items: center;
    display: flex;
    padding: ${(props) => props.theme.spacing.sp32};
    ${ResponsiveMediaQuery('tablet')} {
      padding: ${(props) => props.theme.spacing.sp8} 0;
    }
    a {
      text-decoration: none;
    }
    a:hover {
      color: ${(props) => props.theme.colors.primary};
    }
    ${helpers}
  `,

  Wrapper: styled(Flex)`
    flex-direction: column;

    ${ResponsiveMediaQuery('tablet')} {
      flex-direction: row;
    }
  `,

  Contact: styled(Flex)`
    margin-bottom: ${(props) => props.theme.spacing.sp32};

    ${ResponsiveMediaQuery('tablet')} {
      margin-bottom: 0;
    }

    h5 {
      font-size: 18px;
    }

    p {
      font-size: 16px;

      a {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    img {
      margin-right: ${(props) => props.theme.spacing.sp16};
    }
  `,

  Social: styled.div`
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    :hover {
      background-color: ${(props) => props.theme.colors.primary};
      img,
      svg {
        filter: brightness(0) invert(1);
      }
    }

    img,
    svg {
      width: 24px;
    }
  `,

  Legal: styled(Flex)`
    justify-content: center;

    p {
      text-align: center;
      font-size: 13px;

      ${ResponsiveMediaQuery('tablet')} {
        text-align: right;
      }
    }
  `
};
