import React from 'react';
import parse from 'html-react-parser';
import HtmlParsedContentStyles, { HtmlParsedContentStylesProps } from './styles';

type HtmlParsedContentProps = HtmlParsedContentStylesProps & { content?: string | null };

const HtmlParsedContent: React.FC<HtmlParsedContentProps> = ({ content, ...props }) =>
  content ? (
    <HtmlParsedContentStyles.ParsedContent {...props}>{parse(content)}</HtmlParsedContentStyles.ParsedContent>
  ) : null;

export default HtmlParsedContent;
