import Link from 'next/link';
import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Container from '../../styles/Container';
import Flex from '../../styles/Flex';
import Spacing from '../../styles/Spacing';
import Text from '../../styles/Text';
import { GetMetadataDetailsQuery } from '../../typings/generated/backendGraphql';
// import { useMediaUrl } from '../../utils/mediaUtils';
// import Symbol from '../Icon/ECSymbol';
import FooterStyles from './styles';
import { useLocale } from '../Providers/localeProvider';

type FooterProps = {
  clientId: string;
  data?: GetMetadataDetailsQuery;
  loading?: boolean;
  noMarginTop?: boolean;
};

const Footer: React.FC<FooterProps> = ({ data, clientId, noMarginTop }) => {
  const locale = useLocale();
  const intl = useIntl();
  const emailPlaceholder = useMemo(() => intl.formatMessage({ id: 'storefrontPlaceholder.email' }), [intl]);
  const namePlaceholder = useMemo(() => intl.formatMessage({ id: 'storefrontPlaceholder.name' }), [intl]);

  const storeFront = useMemo(
    () =>
      data && data.metadataStoreFronts && data.metadataStoreFronts.length > 0 ? data.metadataStoreFronts[0] : undefined,
    [data]
  );

  return (
    <Spacing marginTop={!noMarginTop && 'sp96'}>
      <FooterStyles.FooterContainer>
        <Container className="m-0 w-full">
          <FooterStyles.Wrapper fullWidth justifyContent="space-between">
            <FooterStyles.Contact>
              <img
                src={`/static/storeFronts/${clientId}/wordlessLogo.svg`}
                alt={`${storeFront?.name || namePlaceholder} logo`}
              />
              <Flex vertical>
                <Text.H5 color="paragraphOnInverted">
                  <FormattedMessage id="footer.contactUsQuestions" />
                </Text.H5>
                <Text.Paragraph color="paragraphOnInverted" weight="normal">
                  <FormattedMessage
                    id="footer.contactUsCTALabel"
                    values={{
                      email: (
                        <a href={`mailto:${storeFront?.email || emailPlaceholder}`}>
                          {storeFront?.email || emailPlaceholder}
                        </a>
                      )
                    }}
                  />
                </Text.Paragraph>
              </Flex>
            </FooterStyles.Contact>
            <FooterStyles.Legal vertical>
              <Text.SmallerParagraph color="paragraphOnInverted" className="font-black">
                <FormattedMessage id="footer.copyrightLabel" values={{ name: storeFront?.name || namePlaceholder }} />
              </Text.SmallerParagraph>
              <Text.SmallerParagraph align="end" color="paragraphOnInverted" className="font-black">
                <Link href={`/[locale]/[...postSlugs]`} as={`/${locale}/terms-of-use`}>
                  <a>
                    <FormattedMessage id="footer.termsLabel" />
                  </a>
                </Link>{' '}
                |{' '}
                <Link href={`/[locale]/[...postSlugs]`} as={`/${locale}/privacy-policy`}>
                  <a>
                    <FormattedMessage id="footer.privacyLabel" />
                  </a>
                </Link>{' '}
                |{' '}
                <Link href={`/[locale]/[...postSlugs]`} as={`/${locale}/about-us`}>
                  <a>
                    <FormattedMessage id="footer.aboutUsLabel" />
                  </a>
                </Link>
              </Text.SmallerParagraph>
            </FooterStyles.Legal>
          </FooterStyles.Wrapper>
        </Container>
      </FooterStyles.FooterContainer>
    </Spacing>
  );
};

export default React.memo(Footer);
