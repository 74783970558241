import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { GET_METADATA_DETAILS } from '../../apollo/modules/metadata';
import Footer from '../../components/Footer';
import env from '../../env';
import { GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables } from '../../typings/generated/backendGraphql';

type FooterElementProps = {
  noMarginTop?: boolean;
};

const FooterElement: React.FC<FooterElementProps> = ({ noMarginTop }) => {
  const { data, loading } = useQuery<GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables>(GET_METADATA_DETAILS);

  const clientId = useMemo(() => env().NEXT_PUBLIC_CLIENT_ID || 'no-client', []);

  return <Footer data={data} loading={loading} clientId={clientId} noMarginTop={noMarginTop} />;
};

export default FooterElement;
