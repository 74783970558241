import styled from 'styled-components';
import { AppTheme } from '../../styles/Theme';
import ResponsiveMediaQuery from '../../styles/ResponsiveMediaQuery';

export type HtmlParsedContentStylesProps = {
  colorParagraph?: keyof AppTheme['colors'];
};

export default {
  ParsedContent: styled.div<HtmlParsedContentStylesProps>`
    * {
      color: ${(props) => props.theme.colors.paragraph};
      font-weight: 400;
      line-height: 24px;
    }
    /* For every element inside this component, but only the first nested */
    > * {
      /* If first one */
      /* Gets rid of the margin top that would be added to headings when they're in the first line */
      &:first-child {
        margin-top: 0;
      }
    }
    overflow: hidden;

    a {
      text-decoration: underline;
    }

    ul,
    ol {
      padding-left: ${(props) => props.theme.spacing.sp32};
      margin-top: ${(props) => props.theme.spacing.sp16};
      list-style-position: outside;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 900;
      margin: ${(props) => props.theme.spacing.sp16} 0;
    }

    h1 {
      font-size: 24px;
      line-height: 28px;

      &:first-child {
        font-size: 32px;
        line-height: 38px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 28px;
    }

    h3,
    h4,
    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    strong,
    strong a,
    b {
      font-weight: 700;
      color: ${(props) => props.theme.colors.paragraph};
    }

    p,
    ol,
    strong {
      color: ${(props) => props.colorParagraph || props.theme.colors.paragraph};
      line-height: 28px;
      letter-spacing: 0.2px;
      margin-bottom: ${(props) => props.theme.spacing.sp24};
      a {
        color: ${(props) => props.theme.colors.paragraph};
      }
    }

    p,
    em,
    li,
    a,
    sup,
    div,
    span {
      font-weight: 300;
      letter-spacing: 0.2px;
    }

    a:hover,
    a:hover strong,
    a:hover p {
      color: ${(props) => props.theme.colors.primary};
    }

    li {
      margin-bottom: 15px;
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: ${(props) => props.theme.spacing.sp32};
    }

    iframe {
      width: 100%;
      min-height: 100px;
      ${ResponsiveMediaQuery('laptop')} {
        min-height: 400px;
      }
    }
  `
};
